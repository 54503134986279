import React from "react"

const svgRggndr = () => (
  <svg x="0px" y="0px" viewBox="0 0 1284 188">
    <g>
      <g transform="translate(-80.000000, -92.000000)">
        <g transform="translate(0.000000, 40.000000)">
          <g transform="translate(76.000000, 37.000000)">
            <g>
              <path d="M207.3,126c0,15.2-0.6,27.7-1.9,37.7c-1.3,10-3.6,17.9-6.9,23.6c-3.4,5.8-8,9.7-13.8,12
						c-5.9,2.2-13.3,3.3-22.3,3.3c-7.7,0-14.4-0.9-20.2-2.7c-5.8-1.8-10.6-5.1-14.5-9.9c-3.9-4.8-6.9-11.4-8.9-19.9
						c-2-8.4-3-19.3-3-32.5c0-8.3-1.1-13.6-3.3-16.1c-2.2-2.5-6.4-3.7-12.5-3.7h-8.4v10.7c0,6.7,0.8,10.8,2.3,12.1
						c1.5,1.3,6.6,2,15.3,2v57.3H4v-59.6c9,0,14.4-0.8,16-2.4c1.6-1.6,2.4-6.5,2.4-14.5V97.5c0-9.4-0.8-15.1-2.4-17
						c-1.6-1.9-7-2.9-16-2.9V18.3h121.5c27.1,0,46.3,3.8,57.7,11.5c11.4,7.7,17.1,21.2,17.1,40.6c0,8.8-1.7,16-5.2,21.5
						c-3.5,5.5-8.3,9.6-14.5,12.5c-6.2,2.9-13.7,4.8-22.5,5.8c-8.7,1-18.3,1.4-28.7,1.4h-0.3v6.3H169c6.7,0,11.8,1,15.1,3
						c3.4,2,5,5.8,5,11.4v1.4c0,3.3,0.4,5.6,1.2,6.9c0.8,1.3,2.1,2,4,2c2.1,0,3.6-1.2,4.3-3.6c0.8-2.4,1.2-6.9,1.2-13.4
						c0-1.9,0-4.2-0.1-6.9c-0.1-2.7-0.2-5.9-0.4-9.5h7.8c0.2,3.6,0.3,6.9,0.3,9.8S207.3,124.3,207.3,126z M106.5,77.6h-15v31.7h13.2
						c10,0,16.6-1.1,19.9-3.3c3.3-2.2,4.9-6.3,4.9-12.2c0-6.7-1.5-11.1-4.5-13.1C122.1,78.6,115.9,77.6,106.5,77.6z"/>
              <path d="M417.3,134.3v65.4h-75.7v-72.3h-6.9c-1.2,15.4-3,27.9-5.6,37.7s-6,17.4-10.2,22.9
						c-4.2,5.5-9.4,9.3-15.6,11.4c-6.1,2.1-13.2,3.2-21.3,3.2c-23,0-39.6-7.8-49.7-23.3s-15.1-39-15.1-70.3c0-13.8,1.2-26.5,3.6-38
						c2.4-11.5,6.2-21.4,11.4-29.5c5.2-8.2,12-14.5,20.3-19.2c8.4-4.6,18.5-6.9,30.4-6.9c7.9,0,14.7,1,20.6,2.9
						c5.9,1.9,10.7,5.5,14.5,10.8c3.8,5.3,6.9,12.7,9.2,22.3c2.3,9.6,4,21.9,5.2,36.9h7.2v-70H417v79.8h-89
						c-0.8-6.1-2.7-10.4-5.8-12.8c-3.1-2.4-6.4-3.6-10.1-3.6c-5.6,0-9.6,2.4-12.1,7.1c-2.5,4.7-3.7,11.5-3.7,20.3
						c0,11.9,1.5,20.1,4.5,24.6c3,4.5,7.7,6.8,14.3,6.8c4.8,0,8.4-1,10.7-3c2.3-2,3.5-5.7,3.5-11.1c0-3.8-0.9-6.3-2.7-7.3
						c-1.8-1.1-6.5-1.6-14-1.6v-8.6h116.9v8.6c-6,0-9.5,0.9-10.5,2.6C417.8,121.7,417.3,126.5,417.3,134.3z"/>
              <path d="M635.6,134.3v65.4h-75.7v-72.3h-6.9c-1.2,15.4-3,27.9-5.6,37.7s-6,17.4-10.2,22.9
						c-4.2,5.5-9.4,9.3-15.6,11.4c-6.1,2.1-13.2,3.2-21.3,3.2c-23,0-39.6-7.8-49.7-23.3c-10.1-15.6-15.1-39-15.1-70.3
						c0-13.8,1.2-26.5,3.6-38c2.4-11.5,6.2-21.4,11.4-29.5c5.2-8.2,12-14.5,20.3-19.2c8.4-4.6,18.5-6.9,30.4-6.9
						c7.9,0,14.7,1,20.6,2.9c5.9,1.9,10.7,5.5,14.5,10.8c3.8,5.3,6.9,12.7,9.2,22.3c2.3,9.6,4,21.9,5.2,36.9h7.2v-70h77.5v79.8h-89
						c-0.8-6.1-2.7-10.4-5.8-12.8c-3.1-2.4-6.4-3.6-10.1-3.6c-5.6,0-9.6,2.4-12.1,7.1c-2.5,4.7-3.7,11.5-3.7,20.3
						c0,11.9,1.5,20.1,4.5,24.6c3,4.5,7.7,6.8,14.3,6.8c4.8,0,8.4-1,10.7-3c2.3-2,3.5-5.7,3.5-11.1c0-3.8-0.9-6.3-2.7-7.3
						c-1.8-1.1-6.5-1.6-14-1.6v-8.6h116.9v8.6c-6,0-9.5,0.9-10.5,2.6C636.1,121.7,635.6,126.5,635.6,134.3z"/>
              <path d="M730.3,140.1v59.6h-76v-59.6c6.5-0.8,11.2-4.4,14.1-10.9c2.9-6.5,4.3-15.4,4.3-26.5v-8.4
						c0-7.9-0.7-12.6-2.2-14.3c-1.4-1.6-6.9-2.4-16.3-2.4V18.3h114.3v54.4c0,7.1,0.9,12.3,2.6,15.7c1.7,3.4,6,7.7,13,13.1l46.1,34
						h0.3v-2c-0.2-5.9-1.6-12-4.3-18c-2.7-6-6.3-11.7-10.8-16.8c-4.5-5.2-9.7-9.6-15.6-13.4c-5.9-3.7-12-6.3-18.3-7.6V18.3h73.7v59
						c-5.4,1-9.5,3.8-12.2,8.6c-2.8,4.8-4.2,13.2-4.2,25.3v88.4h-95.6v-57.3c0-6.3-1.1-11.4-3.3-15.3c-2.2-3.8-6-7.8-11.4-11.8
						l-47.5-34c0.8,11.5,4.9,22.4,12.5,32.5C701.2,124.1,713.4,132.8,730.3,140.1z"/>
              <path d="M977.1,199.7H869.7v-59.6c9.8,0,15.5-0.8,17.1-2.4c1.6-1.6,2.4-6.5,2.4-14.5V94.6c0-8.1-0.8-12.9-2.4-14.5
						c-1.6-1.6-7.3-2.4-17.1-2.4V18.3h101.4c18.6,0,34.4,2,47.2,5.9c12.9,3.9,23.2,9.7,31,17.4c7.8,7.7,13.3,17.2,16.7,28.5
						c3.4,11.3,5,24.3,5,38.9c0,15.2-1.6,28.5-4.8,39.9c-3.2,11.4-8.4,20.9-15.7,28.4c-7.3,7.5-16.9,13.1-28.9,16.8
						C1009.6,197.8,994.8,199.7,977.1,199.7z M973.7,77.9h-15.6v61.9h13.2c6.5,0,12.6-1.8,18.1-5.3c5.6-3.5,8.4-12,8.4-25.5
						c0-12.7-1.7-21-5-25.1C989.5,79.9,983.1,77.9,973.7,77.9z"/>
              <path d="M1287.6,126c0,15.2-0.6,27.7-1.9,37.7c-1.3,10-3.6,17.9-6.9,23.6c-3.4,5.8-8,9.7-13.8,12
						c-5.9,2.2-13.3,3.3-22.3,3.3c-7.7,0-14.4-0.9-20.2-2.7c-5.8-1.8-10.6-5.1-14.5-9.9c-3.9-4.8-6.9-11.4-8.9-19.9
						c-2-8.4-3-19.3-3-32.5c0-8.3-1.1-13.6-3.3-16.1c-2.2-2.5-6.4-3.7-12.5-3.7h-8.4v10.7c0,6.7,0.8,10.8,2.3,12.1
						c1.5,1.3,6.6,2,15.3,2v57.3h-105.1v-59.6c9,0,14.4-0.8,16-2.4c1.6-1.6,2.4-6.5,2.4-14.5V97.5c0-9.4-0.8-15.1-2.4-17
						c-1.6-1.9-7-2.9-16-2.9V18.3h121.5c27.1,0,46.3,3.8,57.7,11.5c11.4,7.7,17.1,21.2,17.1,40.6c0,8.8-1.7,16-5.2,21.5
						c-3.5,5.5-8.3,9.6-14.5,12.5c-6.2,2.9-13.7,4.8-22.5,5.8c-8.7,1-18.3,1.4-28.7,1.4h-0.3v6.3h39.7c6.7,0,11.8,1,15.1,3
						c3.4,2,5,5.8,5,11.4v1.4c0,3.3,0.4,5.6,1.2,6.9c0.8,1.3,2.1,2,4,2c2.1,0,3.6-1.2,4.3-3.6c0.8-2.4,1.2-6.9,1.2-13.4
						c0-1.9,0-4.2-0.1-6.9c-0.1-2.7-0.2-5.9-0.4-9.5h7.8c0.2,3.6,0.3,6.9,0.3,9.8S1287.6,124.3,1287.6,126z M1186.8,77.6h-15v31.7
						h13.2c10,0,16.6-1.1,19.9-3.3c3.3-2.2,4.9-6.3,4.9-12.2c0-6.7-1.5-11.1-4.5-13.1C1202.4,78.6,1196.2,77.6,1186.8,77.6z"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default svgRggndr
