import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

export const SpotifyTopArtists = () => {
  const data = useStaticQuery(graphql`
      query SpotifyTopArtistsQuery {
          allSpotifyTopArtist(
              filter: { time_range: { eq: "short_term" } }
              limit: 5
              sort: { fields: order }
            ) {
              edges {
                node {
                  external_urls{
                    spotify
                  }
                  name
                }
              }
            }
      }
  `)
  return (
    <div>
      { data.allSpotifyTopArtist.edges &&
        <div>
          {data.allSpotifyTopArtist.edges.map((artist, index) =>
            <div key={index}>
              <Link to={artist.node.external_urls.spotify} target="_blank" rel="noopener" className="link">{artist.node.name}</Link>
            </div>
          )}
        </div>
      }
    </div>
  )
}

export default SpotifyTopArtists
