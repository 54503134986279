import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

export const SpotifyPlaylists = () => {
  const data = useStaticQuery(graphql`
      query SpotifyPlaylistsQuery {
        allSpotifyPlaylist(
          filter: {public: {eq: true}}
          limit: 5
          ) {
            edges {
              node {
                name
                external_urls {
                  spotify
                }
              }
            }
          }
      }
  `)
  return (
    <div>
      { data.allSpotifyPlaylist.edges &&
        <div>
          {data.allSpotifyPlaylist.edges.map((playlist, index) =>
            <div key={index}>
              <Link to={playlist.node.external_urls.spotify} target="_blank" rel="noopener" className="link">{playlist.node.name}</Link>
            </div>
          )}
        </div>
      }
    </div>
  )
}

export default SpotifyPlaylists
